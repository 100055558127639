import React, { lazy } from "react";
import { createRoot } from "react-dom/client";
import "./i18n";
import { AuthProvider as OIDCAuthProvider } from "react-oidc-context";
import { KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM, KEYCLOAK_URL } from "./constants";

const App = lazy(() =>
  import("./App").then((module) => ({
    default: module.default
  }))
);

const FullScreenLoader = lazy(() =>
  import("./components/full-screen-loader").then((module) => ({
    default: module.FullScreenLoader
  }))
);

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <OIDCAuthProvider
    authority={`${KEYCLOAK_URL}/realms/${KEYCLOAK_REALM}`}
    client_id={KEYCLOAK_CLIENT_ID}
    redirect_uri={window.location.href}
    accessTokenExpiringNotificationTimeInSeconds={10}
    revokeTokensOnSignout={true}
  >
    <React.Suspense fallback={<FullScreenLoader />}>
      <App />
    </React.Suspense>
  </OIDCAuthProvider>
);
