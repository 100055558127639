import { t } from 'i18next';

export const API_URL =
  process.env.REACT_APP_API_URL || // Если переменная задана в .env, используем её
  (process.env.REACT_APP_ENVIRONMENT_CONNECT_TO_DEV === 'true'
    ? 'https://development.crypto.extract-sweet.host/server' // Если переменная true
    : `${window.location.protocol}//${window.location.host.replace('cp.', '')}/server`); // Если переменная false

export const KEYCLOAK_URL =
  process.env.REACT_APP_KEYCLOAK_URL ||
  (process.env.REACT_APP_ENVIRONMENT_CONNECT_TO_DEV === 'true'
    ? 'https://id.development.crypto.extract-sweet.host'
    : `${window.location.protocol}//${window.location.host.replace('cp.', '')}`);

export const ACCOUNT_URL =
  process.env.REACT_APP_ACCOUNT_URL ||
  (process.env.REACT_APP_ENVIRONMENT_CONNECT_TO_DEV === 'true'
    ? 'https://development.crypto.extract-sweet.host/account'
    : `${window.location.protocol}//${window.location.host.replace('cp.', '')}/account`);

export const CENTRIFUGE_URL =
  process.env.CENTRIFUGE_URL ??
  `${window.location.protocol}//centrifugo.${window.location.host.replace('cp.', '')}`;

export const KEYCLOAK_REALM = 'master';

export const CURRENCY = ['BTC', 'LTC', 'ETH', 'USDC', 'USDT'];
export const PAIRS = ['BTCETH', 'BTCUSDT', 'BTCUSDC'];
export const KEYCLOAK_CLIENT_ID = 'crypto.admin';

export const ACCOUNT_DATA_PROVIDER = 'account';
export const P2P_DATA_PROVIDER = 'p2p';

export const CONFIG_DATA_PROVIDER = 'config';

export const EXCHANGE_DATA_PROVIDER = 'exchange';
export const ETH_COIN_DATA_PROVIDER = 'eth-coin';
export const BTC_COIN_DATA_PROVIDER = 'btc-coin';
export const LTC_COIN_DATA_PROVIDER = 'ltc-coin';
export const USDT_COIN_DATA_PROVIDER = 'usdt-coin';
export const USDC_COIN_DATA_PROVIDER = 'usdc-coin';

export const BTCETH_SPOT_DATA_PROVIDER = 'btceth-spot';
export const MATIC_COIN_DATA_PROVIDER = 'matic-coin';

// Common
export const typesOptions = [
  { name: t('statuses.buy'), value: 'buy' },
  { name: t('statuses.sell'), value: 'sell' },
];
